import React, { Component } from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import SocketName from "./constants/socket-channels";
import Header from "./containers/header/header";
import {
  getCredits,
  getProjectInfo,
  getUserDetails,
  setAnimoProjectDetails,
  setProjectDetails,
  setUserPaymentDetails,
} from "./redux/actions/projectUtils";
import { sendSocketMessage } from "./socket";
import ContentValue from "./constants/lang/en.json";
import Sidebar from "./containers/sidebar/sidebar";
import Modal from "./containers/modal/modal";
import Timeline from "./containers/timeline/timeline";
import { OuterLayer, Wrapper } from "./containers/sidebar/sidebar-components";
import Workspace from "./containers/workspace/workspace";
import { CONSTANTS, PANEL, PANEL_ITEMS } from "./constants";
import Toast from "./common-components/Toast";
import Loader from "./common-components/Loader";
import { validateKeydown } from "./helper/keymap";
import AppConfig from "./constants/config";
import proceedWait from "./helper/proceedWait";
import {
  createNew,
  getColorThemes,
  getFavouritesData,
  getGiphyData,
  getLanguages,
  getLibraryData,
  getPanelData,
  getPresetItems,
  getViralStyles,
  setPropertyPanel,
  setSubtitleCallback,
  updatePanelItems,
  getAvatarData,
} from "./redux/actions/appUtils";
import openUrl from "./helper/openUrl";
import ServiceWorkerRegister from "./sw-register/sw-register";
import {
  GiphyPanelLoadable,
  ImagePanelLoadable,
  MusicPanelLoadable,
  FavoritesPanelLoadable,
  PropertyPanelLoadable,
  TextPanelLoadable,
  UploadPanelLoadable,
  VideoPanelLoadable,
  SubtitlePanelLoadable,
  UnsplashPanelLoadable,
  PexelsPanelLoadable,
  PexelsVideoPanelLoadable,
  PixabayPanelLoadable,
  PixabayVideoPanelLoadable,
  AvatarPanelLoadable
} from "./helper/loadableComponent";
import WebWorkerRegister from "./webworkers/webworkers";
import mobileDetection from "./helper/hoc/mobileDetection";
import withNotify from "./helper/hoc/withNotify";
import vmTheme from "./constants/theme";
import getQueryParams from "./helper/getQueryParams";
import AudioDetacher from "./containers/audioDetacher/audioDetacher";
import BackdropLoader from "./common-components/BackdropLoader";
import UploadWorkspaceWrapper from "./containers/workspace/upload-workspace-wrapper";
import SubtitleReconcile from "./containers/panels/library-panels/subtitle-panel/subtitle-reconcile";
import Upgrade from "./containers/upgrade/upgrade";
import SupportForm from "./containers/upgrade/supportForm";
import AvatarForm from "./common-components/AvatarForm";

const { LIBRARY_TYPE } = CONSTANTS;

const { IMAGE, MUSIC, VIDEO, PROPERTY, GIPHY } = LIBRARY_TYPE;

const { ANIMO_RENDER } = AppConfig;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      progress: 10,
      playerControl: "",
      workspace: "",
      timeline: "",
      menuBar: "",
      library: "",
      textProperty: "",
      outerLayerVisible: true,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleDropdownClick = this.handleDropdownClick.bind(this);
    this.setOuterLayerVisible = this.setOuterLayerVisible.bind(this);
  }

  componentDidMount() {
    const { params = { id: "" } } = this.props.match;
    this.blockBrowserContextMenu = (e) => e.preventDefault();
    window.addEventListener("contextmenu", this.blockBrowserContextMenu);
    window.addEventListener("keydown", this.handleKeyDown, false);
    window.addEventListener("keyup", this.handleKeyUp, false);
    if (this.props.isLoggedIn) {
      sendSocketMessage(SocketName.TEST, {});
    } else if (ANIMO_RENDER) {
      const subtitleCallback = getQueryParams("subtitleCallback");
      this.props.subtitleCallback({ subtitleCb: subtitleCallback });
      const isSubtitle = getQueryParams("isSubtitle");
      this.props.setAnimoProject(params.id, isSubtitle === "true").then(() => {
        this.setState({
          progress: 110,
        });
      });
    } else {
      getUserDetails().then((response) => {
        this.setState((prevState) => ({
          progress: prevState.progress + 35,
        }));
        if (params.id === "new") {
          this.props.createProject();
        } else {
          this.props.setCredits();
          this.props.setPanelItems(PANEL_ITEMS(this.props.theme));
          this.props.getPresetItem();
          this.props.getUserPayment(params.id);
          this.props.setProject(params.id, response, true).then((res) => {
            const hasShorts = res.project.shorts && Object.keys(res.project.shorts).length !== 0;
            if (hasShorts) {
              openUrl(`${window.location.origin}/shorts/${params.id}`, true);
            } else {
              this.setState((prevState) => ({
                progress: prevState.progress + 25,
              }));
              this.getLibraryData();
              this.props.setProjectData(params.id);
              const { project } = res;
              const { workspaceItems } = project;
              const queryParams = new URLSearchParams(window.location.search);
              const query = queryParams.get('path')?.toUpperCase() || "";
              const path = PANEL[query];
              if (path || !Object.keys(workspaceItems).length) {
                setTimeout(() => {
                  this.props.setPanel(path || PANEL.UPLOAD, true);
                }, 1000)
              }
            }
          }).catch(async () => {
            this.props.notify.warn(
              "You don't have access to this project."
            );
            await proceedWait(3000);
            openUrl(AppConfig.API_URL, true);
          });
          this.props.getColorThemes(response);
        }
      }).catch(async () => {
        this.props.notify.warn("Please login to access to this project");
        await proceedWait(3000);
        openUrl(AppConfig.API_URL, true);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.progress > 100) {
      this.setState({ loading: false, progress: 100 });
    } else if (this.state.progress === 100 && this.state.loading) {
      this.setState({ loading: false });
    }
    if (this.props.fontLoaded && !prevProps.fontLoaded) {
      this.setState((prevState) => ({
        progress: prevState.progress + 15,
      }));
    }
    if (
      this.props.initStages.get("screenshotTaken") &&
      !prevProps.initStages.get("screenshotTaken")
    ) {
      this.setState((prevState) => ({
        progress: prevState.progress + 1,
      }));
    }
    if (
      this.props.initStages.get("wwReady") &&
      !prevProps.initStages.get("wwReady")
    ) {
      this.setState((prevState) => ({
        progress: prevState.progress + 4,
      }));
    }
    if (
      this.props.initStages.get("colorPaletteLoaded") &&
      !prevProps.initStages.get("colorPaletteLoaded")
    ) {
      this.setState((prevState) => ({
        progress: prevState.progress + 10,
      }));
    }
  }

  componentWillUnmount() {
    window.removeEventListener("contextmenu", this.blockBrowserContextMenu);
    window.removeEventListener("keydown", this.handleKeyDown, false);
    window.removeEventListener("keyup", this.handleKeyUp, false);
  }

  handleKeyDown(e) {
    const { isPlayAll, isWorkspaceTextFocus, textStatus } = this.props;

    const optionArr = validateKeydown(e, isWorkspaceTextFocus, textStatus);
    const tempState = {};
    optionArr.forEach(({ component, option }) => {
      if (
        (!isPlayAll || component === "playerControl") &&
        ((!isWorkspaceTextFocus && !textStatus.get("isFocused")) ||
          component === "textProperty" ||
          option === "UNDO_PROJECT" ||
          option === "REDO_PROJECT")
      ) {
        // Prevent the default behaviour on arrow click.
        if (["ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
          e.preventDefault();
        }
        let keyoption = option;
        if (keyoption !== "") {
          if (this.state[component] === keyoption) keyoption += "_AGAIN";
          tempState[component] = keyoption;
        }
      }
    });
    if (Object.keys(tempState).length > 0 && !this.props.swapDetails) {
      // Prevent shortcut while swaping
      this.setState(tempState);
    }
  }

  handleKeyUp() {
    const {
      playerControl,
      workspace,
      timeline,
      menuBar,
      library,
      textProperty,
    } = this.state;
    try {
      if (
        playerControl ||
        workspace ||
        timeline ||
        menuBar ||
        library ||
        textProperty
      ) {
        this.setState({
          playerControl: "",
          workspace: "",
          timeline: "",
          menuBar: "",
          library: "",
          textProperty: "",
        });
      }
    } catch (error) {
      // Reset keyboard shortcuts
    }
  }

  getLibraryData = () => {
    const { match, getData, getGif, getFavourite, getViralStyle, getLanguageData, getThirdPartyLibrary, getAvatar } = this.props;
    const { params = { id: "" } } = match;
    Promise.all([
      getData(0, {
        type: IMAGE.MASSTOCKIMG,
        projectId: params.id,
      }),
      getData(0, {
        type: MUSIC.BGM,
        projectId: params.id,
        size: 25,
        categories: "All Items"
      }),
      getData(0, {
        type: VIDEO.MASSTOCKVID,
        projectId: params.id,
      }),
      getData(0, {
        type: PROPERTY.MASPROP,
        projectId: params.id,
        size: 10,
      }),
      getData(0, {
        type: MUSIC.MASSFX,
        projectId: params.id,
      }),
      getGif(0, {
        type: GIPHY.MASGIPHY,
        projectId: params.id,
      }),
      getFavourite(0, {
        from_app: true
      }),
      getLanguageData(),
      getViralStyle(),
      getThirdPartyLibrary(0, {
        type: VIDEO.PEXELS_VIDEO,
        projectId: params.id,
      }),
      getThirdPartyLibrary(0, {
        type: IMAGE.PEXELS,
        projectId: params.id,
      }),
      getThirdPartyLibrary(0, {
        type: VIDEO.PIXABAY_VIDEO,
        projectId: params.id,
      }),
      getThirdPartyLibrary(0, {
        type: IMAGE.PIXABAY,
        projectId: params.id,
      }),
      getThirdPartyLibrary(0, {
        type: IMAGE.UNSPLASH,
        projectId: params.id,
      }),
      getAvatar(0, "actor"),
      getAvatar(0, "language"),
      getAvatar(0, "voice", 1000),
    ]);
    PropertyPanelLoadable.preload();
    VideoPanelLoadable.preload();
    ImagePanelLoadable.preload();
    UnsplashPanelLoadable.preload();
    UploadPanelLoadable.preload();
    MusicPanelLoadable.preload();
    GiphyPanelLoadable.preload();
    TextPanelLoadable.preload();
    SubtitlePanelLoadable.preload();
    PixabayPanelLoadable.preload();
    PixabayVideoPanelLoadable.preload();
    PexelsPanelLoadable.preload();
    PexelsVideoPanelLoadable.preload();
    FavoritesPanelLoadable.preload();
    AvatarPanelLoadable.preload();
  };

  handleDropdownClick = () => {
    this.setState({ outerLayerVisible: false });
  };

  setOuterLayerVisible(isVisible) {
    this.setState({ outerLayerVisible: isVisible });
  }

  render() {
    const { isMobile, initStages, isLoading, theme, backdropLoader } = this.props;

    const { outerLayerVisible } = this.state;

    const {
      loading,
      progress,
      menuBar,
      workspace,
      library,
      timeline,
      playerControl,
      textProperty,
    } = this.state;

    let headerComp = null;
    if (!ANIMO_RENDER) {
      headerComp = <Header shortcutName={menuBar} />;
    }

    let workspaceComp = null;
    if (initStages.get("projectLoaded")) {
      workspaceComp = (
        <Workspace
          isMobile={isMobile}
          shortcutName={workspace}
          outerLayerVisible={outerLayerVisible}
        />
      );
    }

    const sidebarComp = (
      <Sidebar shortcutName={library} textPropertyShortcut={textProperty} />
    );

    let timelineComp = null;
    if (initStages.get("projectLoaded")) {
      timelineComp = (
        <Timeline
          shortcutName={timeline}
          playerShortcutName={playerControl}
          onDropdownClick={this.handleDropdownClick}
          setOuterLayerVisible={this.setOuterLayerVisible}
        />
      );
    }
    return (
      <IntlProvider locale="en" messages={ContentValue}>
        <ThemeProvider theme={vmTheme[theme]}>
          {ANIMO_RENDER ? null : <ServiceWorkerRegister />}
          <UploadWorkspaceWrapper>
            <WebWorkerRegister />
            {headerComp}
            {workspaceComp}
            {ANIMO_RENDER ? null : (
              <>
                <OuterLayer
                  style={{ display: outerLayerVisible ? "block" : "none" }}
                  className="properties properties-tool-bar"
                >
                  <Wrapper>{sidebarComp}</Wrapper>
                </OuterLayer>
                {timelineComp}
                <Modal />
                <Upgrade />
                <SupportForm />
                <AvatarForm />
                <Toast />
                <AudioDetacher />
                {backdropLoader.get("isLoading") && <BackdropLoader content={backdropLoader.get("loaderMessage")} />}
                {loading && <Loader progress={progress} />}
                {isLoading && <Loader disableProgress={true} />}
                <SubtitleReconcile />
              </>
            )}
          </UploadWorkspaceWrapper>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProject: bindActionCreators(setProjectDetails, dispatch),
    getColorThemes: bindActionCreators(getColorThemes, dispatch),
    setAnimoProject: bindActionCreators(setAnimoProjectDetails, dispatch),
    getUserPayment: bindActionCreators(setUserPaymentDetails, dispatch),
    createProject: bindActionCreators(createNew, dispatch),
    getData: bindActionCreators(getPanelData, dispatch),
    getGif: bindActionCreators(getGiphyData, dispatch),
    getFavourite: bindActionCreators(getFavouritesData, dispatch),
    getThirdPartyLibrary: bindActionCreators(getLibraryData, dispatch),
    getViralStyle: bindActionCreators(getViralStyles, dispatch),
    setProjectData: bindActionCreators(getProjectInfo, dispatch),
    subtitleCallback: bindActionCreators(setSubtitleCallback, dispatch),
    getLanguageData: bindActionCreators(getLanguages, dispatch),
    setPanel: bindActionCreators(setPropertyPanel, dispatch),
    setCredits: bindActionCreators(getCredits, dispatch),
    setPanelItems: bindActionCreators(updatePanelItems, dispatch),
    getPresetItem: bindActionCreators(getPresetItems, dispatch),
    getAvatar: bindActionCreators(getAvatarData, dispatch),
  };
};

const mapStateToProps = ({ userDetails, app }) => {
  const { isLoggedIn } = userDetails;
  return {
    isLoggedIn,
    initStages: app.get("initStages"),
    fontLoaded: app.get("initStages").get("fontsLoaded"),
    isWorkspaceTextFocus: app.get("isWorkspaceTextFocus"),
    textStatus: app.get("textStatus"),
    isPlayAll: app.get("isPlayAll"),
    isLoading: app.get("isLoading"),
    backdropLoader: app.get("backdropLoader"),
    theme: app.get("theme"),
    swapDetails: app.get("swapDetails")
  };
};

App.propTypes = {
  isLoggedIn: PropTypes.bool,
  setProject: PropTypes.func,
  setProjectData: PropTypes.func,
  getColorThemes: PropTypes.func,
  setAnimoProject: PropTypes.func,
  notify: PropTypes.object,
  fontLoaded: PropTypes.bool,
  match: PropTypes.object,
  initStages: PropTypes.object,
  isWorkspaceTextFocus: PropTypes.bool,
  textStatus: PropTypes.object,
  isPlayAll: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  getUserPayment: PropTypes.func,
  getData: PropTypes.func,
  getGif: PropTypes.func,
  getFavourite: PropTypes.func,
  createProject: PropTypes.func,
  getViralStyle: PropTypes.func,
  theme: PropTypes.string,
  swapDetails: PropTypes.object,
  backdropLoader: PropTypes.object,
  subtitleCallback: PropTypes.func,
  getLanguageData: PropTypes.func,
  setPanel: PropTypes.func,
  setCredits: PropTypes.func,
  setPanelItems: PropTypes.func,
  getThirdPartyLibrary: PropTypes.func,
  getPresetItem: PropTypes.func,
  getAvatar: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(mobileDetection(withNotify(App))));
