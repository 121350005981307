/* eslint-disable no-restricted-syntax, no-continue */

import { TimelineMax, TweenMax } from "gsap";
import { Record, fromJS, OrderedMap } from "immutable";
import { MOBILE_THRESHOLD, PANEL, PanelRecord, PropertyWindowRecord } from "../../constants";
import ActionTypes from "../../constants/action-types";
import {
  WorkspaceBoundsRecord,
  WorkspaceStageRecord,
} from "../../containers/workspace/workspace-constants";
import {
  clearTimeline,
  getZoom,
} from "../../containers/workspace/workspace-helper";
import { DEFAULT_CHILDREN_SELECTION } from "../../helper/IndividualSelectHelper";
import { ASSET_URL, UPLOADS, ITEM_CONFIG, ANIMO_RENDER } from "../../constants/config";
import { preloadTextFonts } from "../../containers/text/TextHelper";
import uuid, { randomString } from "../../helper/uuid";
import {
  TextApplyAllOptionsRecord,
  TextOptionsRecord,
  TextStatusRecord,
  libraryTextFonts,
} from "../../containers/text/text-constants";
import { TimelineRunningStateRecord } from "../../containers/timeline/timeline-constants";
import {
  DEFAULT_SWAP_HOVER_DROP,
  LibraryDragItemRecord,
} from "../../containers/panels/library-helper";
import {
  BASE_IMAGE_DETAILS,
  DEFAULT_SELECTED_FRAME_CLIP,
} from "../../containers/frame/frame-helper";
import { ImperativeRotateRecord } from "../../helper/TransformManagerHelper";
import { getSubtitlesFontToLoad } from "../../helper/fontLoadHelper";

const {
  SET_PROPERTY_PANEL,
  SET_EXPAND,
  SET_LOADER,
  SET_VERSION_HISTORY,
  SET_PREVIEW_PROJECT,
  SET_MOBILE_VIEW,
  SWITCH_THEME,
  SHOW_CONTACT_SALES,
  SHOW_SUPPORT_FORM,
  SHOW_UPGRADE_MODAL,
  SET_UPGRADE_MODAL,
  SHOW_AVATAR_FORM
} = ActionTypes;

const StateBeforePlayRecord = Record({
  propertyWindow: PropertyWindowRecord(),
  zoomFactor: 1,
  isFit: true,
  selectedItems: fromJS([]),
  playhead: 0,
});

export const initialState = fromJS({
  browserName: navigator.userAgent.match(
    /(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i
  )[1], // switch to client hints api once it is standardized
  isSaving: false,
  propertyWindow: PropertyWindowRecord(),
  propertyPanel: PanelRecord(),
  animatePropertyWindow: false,
  animateLibrary: false,
  itemtoolAnimationToken: "",
  workspaceStage: WorkspaceStageRecord(),
  workspaceBounds: WorkspaceBoundsRecord(),
  isFit: true,
  zoomFactor: 1,
  projectWidth: 800,
  projectHeight: 450,
  playhead: 0,
  timelineResetToken: "",
  playheadResetToken: "",
  timelineSelectionAutoScrollToken: "",
  selectedItems: [],
  selectedAudios: [],
  selectedSubtitles: [],
  childrenSelection: DEFAULT_CHILDREN_SELECTION,
  textStatus: TextStatusRecord(),
  textOptions: TextOptionsRecord(),
  transformStatus: {
    transforming: false,
    moving: false,
    resizing: false,
    rotating: false,
  },
  imperativeRotate: ImperativeRotateRecord(),
  loadedFonts: { fonts: [] },
  textOffset: 0,
  lastEditText: {},
  actionRecovery: undefined,
  initStages: {
    userLoggedIn: false,
    projectLoaded: false,
    fontsLoaded: false,
    colorPaletteLoaded: false,
    screenshotTaken: Boolean(ANIMO_RENDER),
    swReady: Boolean(ANIMO_RENDER),
    wwReady: Boolean(ANIMO_RENDER),
  },
  workers: {},
  screenshotStatus: ANIMO_RENDER ? "done" : "idle",
  playheadBeforeScreenshot: 0,
  libraryTextFonts,
  hasAllFontsLoaded: true,
  isWorkspaceTextFocus: false,
  runningState: TimelineRunningStateRecord(),
  swapHoverDrop: DEFAULT_SWAP_HOVER_DROP,
  isPlayAll: false,
  t1: null,
  stateBeforePlay: null,
  prefetchToken: "",
  prefetchCount: 0,
  prefetchCompleteCount: 0,
  videoBufferStatus: {},
  isPlayerTweenComplete: false,
  isWsTweenComplete: false,
  libraryDragItem: LibraryDragItemRecord(),
  isWorkspaceDragging: false,
  selectedFrameClip: DEFAULT_SELECTED_FRAME_CLIP,
  isFraming: false,
  isCropping: false,
  frameData: null,
  recentColors: [],
  brandColorPalettes: {},
  themes: {},
  colorPalettes: {},
  userFonts: [],
  isFontLoading: false,
  activeFontName: "",
  activeFontFamily: "",
  textApplyAllData: [],
  txtApplyAllOptions: TextApplyAllOptionsRecord(),
  clipboardData: null,
  isLoading: false,
  isEnableVersionHistory: false,
  swapDetails: null, // replaceItem
  swapInfo: null, // info related to replace
  isMobileView: window.innerWidth <= MOBILE_THRESHOLD,
  previewProjectData: null, // Preview project data from version history.
  theme: "material",
  isAnimLoaded: false,
  showContactSalesForm: false,
  showAvatarForm: false,
  showSupportForm: false,
  isAnimoPlayer: false,
  isShortsPlayer: false,
  globalVolume: 1,
  isPausePlayer: false,
  isFullScreen: false,
  subtitle: {
    autoSubtitle: {
      isLoading: false,
      trackStatus: 0,
    },
    translateSubtitle: {
      isLoading: false,
      percentage: 0,
    },
    reconcileStatus: {
      status: "idle", // idle, pending
      subtitleId: null,
      token: null,
    },
  },
  detachAudioData: null,
  backdropLoader: {},
  subtitleCallback: "",
  languages: null,
  uploadHistory: OrderedMap(),
  animoPlayerId: "",
  shortContainerId: "",
  upgrade: {
    show: false,
    headerText: null,
    subText: null,
    customForm: null,
    isAvatarUpgrade: false,
    upgradesource: "",
    type: "default"
  }
});

export default function appReducer(state = initialState, action) {
  const { type, payload } = action;

  const prevWorkspaceStage = state.get("workspaceStage");
  const prevPropertyWindow = state.get("propertyWindow");
  const prevPropertyPanel = state.get("propertyPanel");

  switch (type) {
    case SET_LOADER: {
      state = state.set("isLoading", payload);
      break;
    }
    case SWITCH_THEME: {
      state = state.set("theme", payload);
      break;
    }
    case SET_VERSION_HISTORY: {
      state = state.set("isEnableVersionHistory", payload);
      if (!payload) {
        state = state.set("previewProjectData", null);
      }
      break;
    }
    case SET_PREVIEW_PROJECT: {
      state = state.set("previewProjectData", payload);
      break;
    }
    case SET_MOBILE_VIEW: {
      state = state.set("isMobileView", payload);
      break;
    }
    case SHOW_CONTACT_SALES: {
      state = state.set("showContactSalesForm", payload);
      break;
    }
    case SHOW_AVATAR_FORM: {
      state = state.set("showAvatarForm", payload);
      break;
    }
    case SHOW_SUPPORT_FORM: {
      state = state.set("showSupportForm", payload);
      break;
    }
    case SHOW_UPGRADE_MODAL: {
      state = state.set("upgrade", state.get("upgrade").merge(payload));
      break;
    }
    case SET_PROPERTY_PANEL:
    case SET_EXPAND: {
      const prevIsExpand = state.getIn(["propertyPanel", "isExpand"]);
      let propertyPanel = state.get("propertyPanel").merge(fromJS(payload));
      if (payload.context && payload.context.retainExpand) {
        propertyPanel = propertyPanel.set("isExpand", prevIsExpand);
      }
      if (
        propertyPanel.get("isExpand") &&
        propertyPanel.get("selectedPanel") === PANEL.NONE
      ) {
        propertyPanel = propertyPanel.set("selectedPanel", PANEL.PROPERTIES);
      }
      state = state.set("propertyPanel", propertyPanel);

      if (
        propertyPanel.get("isExpand") &&
        propertyPanel.get("selectedPanel") !== PANEL.NONE
      ) {
        state = state.set("propertyWindow", PropertyWindowRecord());
      }
      if (
        payload && payload.context && payload.context.isSelected
      ) {
        state = state.setIn(["selectedTransitionItems", "prevItem"], payload.context.prevId);
        state = state.setIn(["selectedTransitionItems", "nextItem"], payload.context.nextId);
      }
      if (!(payload && payload.context && payload.context.isSelected)) {
        state = state.deleteIn(["selectedTransitionItems"]);
      }

      break;
    }
    case ActionTypes.SET_PROPERTY_WINDOW: {
      if (action.data.component) {
        state = state.setIn(
          ["propertyWindow", "component"],
          action.data.component
        );
        state = state.setIn(["propertyWindow", "type"], action.data.type);
        state = state.setIn(
          ["propertyWindow", "isTextColor"],
          Boolean(action.data.isTextColor)
        );

        if (typeof action.data.isOpened === "boolean") {
          state = state.setIn(
            ["propertyWindow", "isOpened"],
            action.data.isOpened
          );
        } else {
          state = state.setIn(["propertyWindow", "isOpened"], true);
        }

        if (state.getIn(["propertyWindow", "component"]) === "subtitle_settings") {
          state = state.setIn(["propertyPanel", "selectedPanel"], PANEL.SUBTITLE)
        }
      } else {
        state = state.set("propertyWindow", PropertyWindowRecord());
      }
      break;
    }
    case ActionTypes.SET_TIMELINE_HEIGHT: {
      state = state.setIn(
        ["workspaceStage", "bottom", "timeline"],
        payload.height
      );
      break;
    }
    case ActionTypes.SET_USER_DATA: {
      state = state.setIn(["initStages", "userLoggedIn"], true);
      break;
    }
    case ActionTypes.SET_PROJECT: {
      state = state
        .setIn(["initStages", "projectLoaded"], true)
        .set("projectWidth", payload.width)
        .set("projectHeight", payload.height);

      const projectData = fromJS(payload);
      let loadedFonts = fromJS([]);
      const stylesToLoad = ["regular", "bold", "italic", "boldItalic"];

      // preload library fonts for instant text drop
      for (const textItem of state.get("libraryTextFonts").valueSeq()) {
        const fontData = textItem.get("data");
        let fileName = fontData.get("fileName");
        if (!fileName.endsWith(".css")) {
          fileName = `${fileName}.css`;
        }
        const url = `${ASSET_URL}animaker/admin-fonts/${fileName}`;

        for (const styleToLoad of stylesToLoad) {
          const style = fontData.get(styleToLoad);
          if (style && typeof style === "string") {
            const includeThisFont =
              loadedFonts.find((x) => x.get("load") === style) === undefined;
            if (includeThisFont) {
              loadedFonts = loadedFonts.push(
                fromJS({
                  font: fontData.get("fontFamily"),
                  load: style,
                  byUser: false,
                  isLoaded: false,
                  url,
                })
              );
            }
          }
        }
      }

      let allTextItems = projectData.get("workspaceItems").filter((value) => {
        return value.get("type") === "TEXT" || value.get("type") === "SHAPE";
      });
      if (projectData.get("workspaceChildren")) {
        allTextItems = allTextItems.merge(
          projectData.get("workspaceChildren").filter((value) => {
            return (
              value.get("type") === "TEXT" || value.get("type") === "SHAPE"
            );
          })
        );
      }

      if (allTextItems.size > 0) {
        allTextItems.valueSeq().forEach((value) => {
          const textData = value.get("textData");
          const includeThisFont =
            loadedFonts.find((x) => {
              return (
                textData.getIn(["formats", "containerStyle", "fontFamily"]) &&
                x.get("load") ===
                textData
                  .getIn(["formats", "containerStyle", "fontFamily"])
                  .replace(/^["]|["]$/g, "")
              );
            }) === undefined;

          if (
            includeThisFont &&
            textData.getIn(["formats", "others", "family"]) !== undefined &&
            (textData.getIn(["formats", "others", "cssUrl"]) ||
              !textData.getIn(["formats", "others", "isUserFont"]))
          ) {
            const userFontUrl = UPLOADS.font_src;
            const assetUrl = ASSET_URL;
            const cssUrl =
              textData.getIn(["formats", "others", "cssUrl"]) ||
              textData.getIn(["formats", "others", "family"]);

            let url = "";
            if (
              textData.getIn(["formats", "others", "isUserFont"]) &&
              !cssUrl.includes("admin-fonts")
            ) {
              if (cssUrl.indexOf("https://") === 0) {
                url = cssUrl;
              } else {
                url = `${userFontUrl}${cssUrl}`;
              }
            } else if (cssUrl.indexOf("https://") === 0) {
              url = cssUrl;
            } else {
              url = `${assetUrl}animaker/admin-fonts/${cssUrl}.css`;
            }

            const includeThisFont =
              loadedFonts.find((x) => x.get("url") === url) === undefined;
            if (includeThisFont) {
              loadedFonts = loadedFonts.push(
                fromJS({
                  font: textData.getIn(["formats", "others", "family"]),
                  load: textData.getIn([
                    "formats",
                    "containerStyle",
                    "fontFamily",
                  ]),
                  byUser:
                    textData.getIn(["formats", "others", "isUserFont"]) ===
                    true,
                  isLoaded: false,
                  url,
                })
              );
            }
          }

          const partialFonts = preloadTextFonts(textData.get("htmlText"));
          for (const fontData of partialFonts) {
            const includeThisFont =
              loadedFonts.find((x) => x.get("url") === fontData.url) ===
              undefined;
            if (includeThisFont) {
              loadedFonts = loadedFonts.push(fromJS(fontData));
            }
          }
        });

        let prevLoadedFonts = state.getIn(["loadedFonts", "fonts"]);
        if (prevLoadedFonts && prevLoadedFonts.size > 0) {
          // when set project is called second time, we need to maintain previous font load status to prevent infinite loader in text component
          const groupedPrevLoadedFonts = prevLoadedFonts.groupBy((loadedFont) =>
            loadedFont.get("load")
          );
          const groupedNewLoadedFonts = loadedFonts.groupBy((loadedFont) =>
            loadedFont.get("load")
          );

          groupedNewLoadedFonts.forEach((newLoadedFontList, newLoad) => {
            if (groupedPrevLoadedFonts.get(newLoad) === undefined) {
              newLoadedFontList.forEach((newLoadedFont) => {
                prevLoadedFonts = prevLoadedFonts.push(newLoadedFont);
              });
            }
          });
          loadedFonts = prevLoadedFonts;
        }
      }

      if (projectData.get("subtitle")) {
        const globalTextStyle = projectData.getIn(["subtitle", "textStyles"]).toJS();
        const subtitleData = projectData.getIn(["subtitle", "data"]);
        const subtitleFonts = getSubtitlesFontToLoad(subtitleData, loadedFonts, globalTextStyle);
        if (subtitleFonts.length) {
          loadedFonts = loadedFonts.concat(fromJS(subtitleFonts));
        }
      }

      state = state.setIn(["loadedFonts", "fonts"], loadedFonts);
      state = state.set("hasAllFontsLoaded", loadedFonts.size === 0);

      const hasSmartThumbnail = Boolean(projectData.get("isSmartThumbnail"));
      const isProjectEmpty =
        projectData.get("workspaceItems").size === 0 &&
        (projectData.getIn(["bgColor", "colors"]).size === 0 ||
          (projectData.getIn(["bgColor", "colors"]).size === 1 &&
            projectData.getIn(["bgColor", "colors", 0]) === "#ffffff"));

      if (isProjectEmpty || hasSmartThumbnail) {
        state = state.setIn(["initStages", "screenshotTaken"], true);
        state = state.set("screenshotStatus", "failed");
      }

      state = state.set("isAnimoSubtitle", action.isAnimoSubtitle);
      break;
    }
    case ActionTypes.FONT_LOADED: {
      const loadedIndex = state
        .getIn(["loadedFonts", "fonts"])
        .findKey((x) => x.get("load") === payload.load);
      if (loadedIndex !== undefined) {
        state = state.setIn(
          ["loadedFonts", "fonts", loadedIndex, "isLoaded"],
          true
        );
      }

      state.getIn(["loadedFonts", "fonts"]).forEach((data, index) => {
        if (data.get("load") === payload.load) {
          state = state.setIn(
            ["loadedFonts", "fonts", index, "isLoaded"],
            true
          );
        }
      });

      const hasAllFontsLoaded = state
        .getIn(["loadedFonts", "fonts"])
        .valueSeq()
        .every((fontObj) => fontObj.get("isLoaded"));
      state = state.set("hasAllFontsLoaded", hasAllFontsLoaded);
      break;
    }
    case ActionTypes.CHANGE_ZOOM: {
      if (payload.isFit) {
        state = state.set("isFit", payload.isFit);
      } else {
        state = state.set("isFit", false).set("zoomFactor", payload.zoomFactor);
      }
      break;
    }
    case ActionTypes.SET_PLAYHEAD_TIME: {
      if (state.get("isPlayAll")) {
        state = state.setIn(["runningState", "seekPlayhead"], payload.playhead);
        state = state.setIn(["runningState", "seekToken"], uuid());
      } else if (payload.playhead !== state.get("playhead")) {
        state = state.set("playhead", payload.playhead);
      }
      break;
    }
    case ActionTypes.SET_INIT_STAGES: {
      const initStages = state.get("initStages").merge(fromJS(payload));
      state = state.set("initStages", initStages);
      break;
    }
    case ActionTypes.SET_SCREENSHOT_STATUS: {
      if (
        payload.status === "pending" ||
        payload.status === "done" ||
        payload.status === "failed"
      ) {
        state = state.set("screenshotStatus", payload.status);
      }

      if (payload.status === "done" || payload.status === "failed") {
        state = state.setIn(["initStages", "screenshotTaken"], true);
        if (state.get("playhead") !== state.get("playheadBeforeScreenshot")) {
          state = state.set("playhead", state.get("playheadBeforeScreenshot"));
          state = state.set("playheadResetToken", uuid());
        }
      }

      if (payload.status === "pending") {
        state = state.set("playheadBeforeScreenshot", state.get("playhead"));
        if (Number.isFinite(payload.playhead)) {
          state = state.set("playhead", payload.playhead);
          state = state.set("playheadResetToken", uuid());
        }
      }
      break;
    }
    case ActionTypes.WEB_WORKER_INIT: {
      if (payload.status === "done") {
        state = state.setIn(["initStages", "wwReady"], true);
        state = state.set("workers", fromJS(payload.workers));
      } else {
        state = state.setIn(["initStages", "wwReady"], false);
        state = state.set("workers", fromJS({}));
      }
      break;
    }
    case ActionTypes.SET_WORKSPACE_TEXT_FOCUS: {
      if (state.getIn(["textStatus", "isFocused"])) {
        state = state.set("isWorkspaceTextFocus", true);
      } else {
        state = state.set("isWorkspaceTextFocus", payload);
      }
      break;
    }
    case ActionTypes.SAVE_START: {
      state = state.set("isSaving", true);
      break;
    }
    case ActionTypes.SAVE_DONE: {
      state = state.set("isSaving", false);
      break;
    }
    case ActionTypes.SET_PLAY_ALL: {
      state = state.set("isPlayAll", payload.isPlayAll);
      let timelineCurrentTime;

      if (state.get("t1") instanceof TimelineMax) {
        if (state.get("isLoaded")) {
          timelineCurrentTime = state.get("t1").time();
          if (timelineCurrentTime === state.get("t1").duration()) {
            timelineCurrentTime = undefined;
          }
        } else {
          timelineCurrentTime = state.getIn(["runningState", "seekPlayhead"]);
        }
        clearTimeline(state.get("t1"));
        state = state.set("t1", null);
      }

      if (state.get("isPlayAll")) {
        // setup gsap for play
        const t1 = new TimelineMax({ paused: true });
        state = state.set("t1", t1);

        // update current playhead
        const playhead = payload.isFullPreview ? 0 : state.get("playhead");
        state = state.set(
          "runningState",
          TimelineRunningStateRecord({
            seekPlayhead: playhead,
            seekToken: uuid(),
          })
        );

        // update prefetch tracker
        state = state.set("prefetchToken", uuid());
        state = state.set("prefetchCount", payload.prefetchCount || 0); // added in autosaveMiddleware
        state = state.set("prefetchCompleteCount", 0);
        state = state.set(
          "isWsTweenComplete",
          state.get("prefetchCount") === state.get("prefetchCompleteCount")
        );

        // store required state for updating it after play
        let stateBeforePlay = StateBeforePlayRecord({
          isFit: state.get("isFit"),
          zoomFactor: state.get("zoomFactor"),
          playhead: state.get("playhead"),
        });

        // reset state
        state = state.set("playhead", playhead);
        state = state.set("isFit", true);
        state = state.set("isWorkspaceTextFocus", false);
        // Skipping property window reset only for subtitle settings. (Note: Avoid this for other settings)
        if ((state.getIn(["propertyWindow", "type"]) !== "SUBTITLE")) {
          state = state.set("propertyWindow", PropertyWindowRecord());
        } else {
          stateBeforePlay = stateBeforePlay.set("propertyWindow", state.get("propertyWindow"));
        }
        state = state.set("selectedItems", fromJS([]));
        state = state.set("selectedAudios", fromJS([]));
        state = state.set("selectedSubtitles", fromJS([]));
        state = state.set("childrenSelection", DEFAULT_CHILDREN_SELECTION);
        state = state.set("textStatus", TextStatusRecord());
        state = state.set("textOptions", TextOptionsRecord());
        state = state.set("videoBufferStatus", fromJS({}));
        state = state.set("isWorkspaceDragging", false);
        state = state.set("stateBeforePlay", stateBeforePlay);
      } else {
        const stateBeforePlay = state.get("stateBeforePlay");
        if (stateBeforePlay) {
          stateBeforePlay
            .toSeq()
            .entrySeq()
            .forEach(([key, stateToSet]) => {
              state = state.set(key, stateToSet);
            });
        }
        state = state.set("stateBeforePlay", null);
        state = state.set("runningState", TimelineRunningStateRecord());
        state = state.set("prefetchToken", "");
        state = state.set("prefetchCount", 0);
        state = state.set("prefetchCompleteCount", 0);
        state = state.set("isPlayerTweenComplete", false);
        state = state.set("isWsTweenComplete", false);
        state = state.set("videoBufferStatus", fromJS({}));
        state = state.set("isLoaded", false);

        if (Number.isFinite(timelineCurrentTime)) {
          state = state.set("playhead", timelineCurrentTime);
        }
      }

      break;
    }
    case ActionTypes.START_PLAY: {
      if (payload === "player") {
        state = state.set("isPlayerTweenComplete", true);
      }
      if (payload === "workspace") {
        state = state.set("isWsTweenComplete", true);
      }

      if (
        state.get("isPlayerTweenComplete") &&
        state.get("isWsTweenComplete") &&
        !state.get("isLoaded")
      ) {
        if (ANIMO_RENDER) {
          state = state.set("isAnimLoaded", true);
        } else {
          state = state.set("isLoaded", true);
        }
      }

      break;
    }
    case ActionTypes.UPDATE_VIDEO_BUFFER_STATUS: {
      const { videoKey, isBuffering, bufferStartedAt } = payload;
      if (isBuffering) {
        state = state.setIn(["videoBufferStatus", videoKey], true);

        state = state.setIn(["runningState", "seekPlayhead"], bufferStartedAt);
        state = state.setIn(["runningState", "seekToken"], uuid());
      } else {
        state = state.deleteIn(["videoBufferStatus", videoKey]);
        if (state.get("videoBufferStatus").size === 0) {
          state = state.setIn(["runningState", "seekToken"], uuid());
        }
      }
      break;
    }
    case ActionTypes.PREFETCH_LOADED: {
      if (payload.token === state.get("prefetchToken")) {
        const prevCount = state.get("prefetchCompleteCount");
        state = state.set("prefetchCompleteCount", prevCount + payload.data);
      }
      break;
    }
    case ActionTypes.ADD_TWEEN: {
      if (state.get("t1") instanceof TimelineMax === false || !state.get("isPlayAll")) {
        // play was stopped on some error before tweens were assigned
        break;
      }

      if (action.tweenType === "addchartween") {
        const tweenDuration = action.data.endTime - action.data.startTime;
        const { timeline } = action.data;
        const maxTweenDuration = timeline.length
          ? timeline.reduce(
            (max, b) =>
              b.time + b.duration < max ? max : b.time + b.duration,
            timeline[0].time + timeline[0].duration
          )
          : 8;

        let isRepeated =
          action.data.isRepeated === "0"
            ? 0
            : Math.ceil(tweenDuration / maxTweenDuration);

        if (action.data.type === "PROP" && action.data.isAnimated) {
          isRepeated = action.data.repeatLoop - 1;
        }
        if (action.data.type === "SCREEN") {
          isRepeated = 0;
        }

        const chart = new TimelineMax({ repeat: isRepeated });
        for (let i = 0; i < action.data.timeline.length; i += 1) {
          chart.add(
            TweenMax.to(
              action.data.timeline[i].domVal,
              action.data.timeline[i].duration,
              action.data.timeline[i].toVal
            ),
            action.data.timeline[i].time
          );
        }

        state.get("t1").add(chart, action.data.startTime);
        if (action.data.type === "SCREEN") {
          state.get("t1").add(
            TweenMax.to(document.getElementById(action.data.idVal), 0.001, {
              opacity: 0,
            }),
            action.data.startTime + 1
          );
        }
      } else if (action.tweenType === "fromTo") {
        state
          .get("t1")
          .add(
            TweenMax.fromTo(
              action.data.playerDOM,
              action.data.startTime,
              action.data.fromVal,
              action.data.toVal
            ),
            action.data.time
          );
      } else if (action.tweenType === "addCallback") {
        state
          .get("t1")
          .addCallback(
            action.data.callBack,
            action.data.startTime,
            action.data.dataArr
          );
      } else if (action.tweenType === "eventCallback") {
        state.get("t1").eventCallback("onUpdate", action.data.callBack);
      } else if (action.tweenType === "onStart") {
        state.get("t1").eventCallback("onStart", action.data.callBack);
      } else if (action.tweenType === "from") {
        state
          .get("t1")
          .add(
            TweenMax.from(
              action.data.playerDOM,
              action.data.startTime,
              action.data.fromVal
            ),
            action.data.time
          );
      } else if (action.tweenType === "to") {
        state
          .get("t1")
          .add(
            TweenMax.to(
              action.data.playerDOM,
              action.data.startTime,
              action.data.toVal
            ),
            action.data.time
          );
      } else if (action.tweenType === "staggerFromTo") {
        state
          .get("t1")
          .add(
            TweenMax.staggerFromTo(
              action.data.playerDOM,
              action.data.startTime,
              action.data.fromVal,
              action.data.toVal,
              action.data.staggerTime
            ),
            action.data.time
          );
      } else if (action.tweenType === "staggerTo") {
        state
          .get("t1")
          .staggerTo(
            action.data.playerDOM,
            action.data.startTime,
            action.data.toVal,
            action.data.staggerTime,
            action.data.time
          );
      } else if (action.tweenType === "staggerFrom") {
        state
          .get("t1")
          .staggerFrom(
            action.data.playerDOM,
            action.data.startTime,
            action.data.fromVal,
            action.data.staggerTime,
            action.data.time,
            action.data.onComplete
          );
      } else if (action.tweenType === "set") {
        if (action.data.setType === "styles") {
          state
            .get("t1")
            .add(TweenMax.set(action.data.playerDOM, action.data.setVal));
        } else {
          const { playerDOM = {}, setVal = {}, time } = action.data;
          state.get("t1").set(playerDOM, setVal, time);
        }
      }
      break;
    }
    case ActionTypes.UPDATE_LIBRARY_DRAG_ITEM: {
      if (payload.reset || !payload.id) {
        state = state.set("libraryDragItem", LibraryDragItemRecord());
      } else {
        let libraryDragItem = state.get("libraryDragItem");
        libraryDragItem = libraryDragItem.set("id", payload.id);
        libraryDragItem = libraryDragItem.set("data", payload.data);
        libraryDragItem = libraryDragItem.set("originalData", payload.originalData);
        state = state.set("libraryDragItem", libraryDragItem);
      }
      break;
    }
    case ActionTypes.SET_WORKSPACE_DRAGGING: {
      state = state.set("isWorkspaceDragging", payload);
      break;
    }
    case ActionTypes.SET_SELECTED_ITEMS: {
      const {
        selectedItems = [],
        selectedAudios = [],
        selectedSubtitles = [],
        propWindowType,
        type,
        playhead,
      } = action.data;
      const prevSelectedItems = state.get("selectedItems");
      const prevSelectedAudios = state.get("selectedAudios");
      const prevSelectedSubtitles = state.get("selectedSubtitles");
      const prevChildrenSelection = state.get("childrenSelection");

      state = state.set("selectedItems", fromJS(selectedItems));
      state = state.set("selectedAudios", fromJS(selectedAudios));
      state = state.set("selectedSubtitles", fromJS(selectedSubtitles));
      state = state.set("isWorkspaceTextFocus", false);

      if (
        selectedItems.length === 0 ||
        selectedItems.length > 1 ||
        (selectedItems.length === 1 &&
          selectedItems[0] !== state.getIn(["childrenSelection", "itemId"]))
      ) {
        state = state.set("childrenSelection", DEFAULT_CHILDREN_SELECTION);
      }

      /*
       * handle clip selection when different item is selected
       */
      if (
        selectedItems.length === 0 ||
        selectedItems.length > 1 ||
        // frame's parent
        (selectedItems.length === 1 &&
          state.getIn(["selectedFrameClip", "groupId"]) &&
          selectedItems[0] !== state.getIn(["selectedFrameClip", "groupId"])) ||
        // frame item
        (selectedItems.length === 1 &&
          state.getIn(["selectedFrameClip", "id"]) &&
          selectedItems[0] !== state.getIn(["selectedFrameClip", "id"]))
      ) {
        state = state.set("selectedFrameClip", DEFAULT_SELECTED_FRAME_CLIP);
      }

      if (state.getIn(["textStatus", "id"]) !== null) {
        if (
          selectedItems.length === 0
          || selectedItems.length > 1
          || selectedItems[0] !== state.getIn(["textStatus", "id"])
        ) {
          // cleanup text status
          state = state.set("textStatus", TextStatusRecord());
        }
      }

      state = state.set("isCropping", false);
      state = state.set("isFraming", false);

      const updatePropWindow =
        !prevSelectedItems.equals(state.get("selectedItems")) ||
        !prevSelectedAudios.equals(state.get("selectedAudios")) ||
        !prevSelectedSubtitles.equals(state.get("selectedSubtitles")) ||
        !prevChildrenSelection.equals(state.get("childrenSelection"));

      if (updatePropWindow) {
        const propertyWindow = {
          component: "",
          isOpened: false,
          type: "",
          isTextColor: false,
        };

        if (state.getIn(["propertyPanel", "isExpand"]) || state.getIn(["propertyWindow", "isOpened"])) {
          if (selectedItems.length === 1 && propWindowType) {
            propertyWindow.component = "settings";
            propertyWindow.isOpened = true;
            propertyWindow.type = type;

            if (
              propWindowType === "IMAGE_SETTINGS" ||
              propWindowType === "VIDEO_SETTINGS"
            ) {
              propertyWindow.type = propWindowType;
            }
          } else if (
            selectedAudios.length === 1 &&
            propWindowType === "AUDIO_SETTINGS"
          ) {
            propertyWindow.component = "settings";
            propertyWindow.isOpened = true;
            propertyWindow.type = propWindowType;
          }
        }

        state = state.set(
          "propertyWindow",
          PropertyWindowRecord(propertyWindow)
        );
      }

      if (Number.isFinite(playhead)) {
        state = state.set("playhead", playhead);
        state = state.set("playheadResetToken", uuid());
      }

      /** @todo handle swap cancellation */
      break;
    }
    case ActionTypes.SET_CHILDREN_SELECTION: {
      state = state.setIn(
        ["childrenSelection", "itemId"],
        action.data.get("itemId")
      );
      state = state.setIn(
        ["childrenSelection", "childIds"],
        action.data.get("childIds")
      );

      if (!action.data.get("isTextSelection")) {
        // need not close prop window when a text is selected
        state = state.set("propertyWindow", PropertyWindowRecord());
      } else if (
        action.data.get("isTextSelection") &&
        state.getIn(["propertyWindow", "isOpened"]) &&
        state.getIn(["propertyWindow", "type"]) !== "TEXT"
      ) {
        // type need to be updated, property window will try to open someother window type
        state = state.setIn(["propertyWindow", "type"], "TEXT");
      }

      const isChildFrameSelected =
        state.getIn(["childrenSelection", "childIds"]).size === 1 &&
        state.getIn(["childrenSelection", "itemId"]) ===
        state.getIn(["selectedFrameClip", "groupId"]) &&
        state.getIn(["childrenSelection", "childIds", 0]) ===
        state.getIn(["selectedFrameClip", "id"]);
      if (!isChildFrameSelected) {
        state = state.set("selectedFrameClip", DEFAULT_SELECTED_FRAME_CLIP);
      }

      break;
    }
    case ActionTypes.UPDATE_TEXT_STATUS: {
      if (action.data.isFocused) {
        state = state.set("isWorkspaceTextFocus", true);
        state = state.set(
          "propertyWindow",
          PropertyWindowRecord({
            component: "settings",
            isOpened: true,
            isTextColor: false,
            type: "TEXT",
          })
        );
      } else if (
        state.getIn(["textStatus", "isFocused"]) &&
        action.data.isFocused === false &&
        !action.data.isSelected
      ) {
        state = state.set("isWorkspaceTextFocus", false);
        state = state.set("propertyWindow", PropertyWindowRecord());
      } else {
        state = state.set("isWorkspaceTextFocus", false);
      }
      state = state.set(
        "textStatus",
        state.get("textStatus").merge(fromJS(action.data))
      );
      break;
    }
    case ActionTypes.SET_TEXT_OPTIONS: {
      let textOptions = state.get("textOptions");

      for (const key of Reflect.ownKeys(action.data)) {
        let property = key;
        let value = action.data[key];
        if (property === "multipleColors") {
          textOptions = textOptions.set("multipleColors", fromJS(value) || []);
        }

        if (property !== "isBullet") {
          if (property !== "colors") {
            if (property === "unlink") {
              value = null;
              property = "link";
            }
            let isActive = value !== null && value !== "normal";
            if (property === "bullet") {
              isActive = textOptions.getIn(["bullet", "isActive"]);
            }
            textOptions = textOptions
              .setIn([property, "value"], value)
              .setIn([property, "isActive"], isActive);
          } else {
            textOptions = textOptions.set("colors", fromJS(value));
          }
        } else {
          textOptions = textOptions.setIn(["bullet", "isActive"], value);
        }
      }

      if (!state.get("textOptions").equals(textOptions))
        state = state.set("textOptions", textOptions);

      break;
    }
    case ActionTypes.SET_LAST_EDIT_TEXT: {
      state = state.set("lastEditText", action.data);
      break;
    }
    case ActionTypes.CHANGE_TEXT: {
      if (action.payload.settings !== undefined) {
        if (action.payload.settings.property !== "isBullet") {
          let { property, value } = action.payload.settings;
          if (property === "fontSize")
            value = value !== null ? parseFloat(value, 10) : null;
          if (property === "unlink") {
            value = null;
            property = "link";
          }
          let isActive = value !== null;

          if (property === "bullet")
            isActive = state.getIn(["textOptions", "bullet", "isActive"]);

          state = state
            .setIn(["textOptions", property, "value"], value)
            .setIn(["textOptions", property, "isActive"], isActive);

          if (property === "color")
            state = state.setIn(["textOptions", "colors"], fromJS([]));
        } else {
          state = state.setIn(
            ["textOptions", "bullet", "isActive"],
            action.payload.settings.value
          );
        }
        if (state.getIn(["textStatus", "selectedAll"]))
          state = state.setIn(["textStatus", "selectedAll"], false);
      }
      break;
    }
    case ActionTypes.BULK_UPDATE_TEXT: {
      Array.from(action.data).forEach((data) => {
        if (data.isGroupUpdate) {
          if (data.propertyUpdate) {
            let { value } = data;
            const { property } = data;
            if (data.property === "fontSize")
              value = value !== null ? parseFloat(value, 10) : null;
            if (data.property === "letterSpacing")
              value = value !== null ? parseFloat(value, 10) : null;

            let isActive = value !== null;
            if (property === "bullet") {
              isActive = state.getIn(["textOptions", "bullet", "isActive"]);

              if (state.getIn(["textOptions", "textAlign", "value"]) !== "left")
                state = state.setIn(
                  ["textOptions", "textAlign", "value"],
                  "left"
                );
            }
            state = state
              .setIn(["textOptions", property, "value"], value)
              .setIn(["textOptions", property, "isActive"], isActive);
            let selectedAll = false;

            if (
              data.selectedAll !== undefined &&
              data.selectedAll &&
              state.getIn(["textStatus", "isFocused"])
            )
              selectedAll = true;

            if (state.getIn(["textStatus", "selectedAll"]) !== selectedAll) {
              state = state.setIn(["textStatus", "selectedAll"], selectedAll);
            }
          }
        } else {
          if (data.isGrouped) {
            state = state.set("textUpdated", 0);
          }

          if (data.property !== "isBullet") {
            let { value } = data;
            const { property } = data;

            if (property === "fontSize")
              value = value !== null ? parseFloat(value, 10) : null;

            if (property === "letterSpacing")
              value = value !== null ? parseFloat(value, 10) : null;

            let isActive = value !== null;

            if (property === "bullet") {
              isActive = state.getIn(["textOptions", "bullet", "isActive"]);

              if (state.getIn(["textOptions", "textAlign", "value"]) !== "left")
                state = state.setIn(
                  ["textOptions", "textAlign", "value"],
                  "left"
                );
            }

            state = state
              .setIn(["textOptions", property, "value"], value)
              .setIn(["textOptions", property, "isActive"], isActive);

            if (property === "fontFamily" && data.textData !== undefined) {
              state = state.setIn(
                ["textOptions", "fontFamily", "value"],
                value
              );

              if (data.fontLoaded === true) {
                const loadedFonts = state.getIn(["loadedFonts", "fonts"]);
                const loadedFont = loadedFonts.find(
                  (x) => x.get("load") === value
                );

                if (loadedFont === undefined) {
                  if (data.family !== undefined)
                    state = state.setIn(
                      ["loadedFonts", "fonts"],
                      loadedFonts.push(
                        fromJS({
                          font: data.family,
                          load: value,
                          byUser: data.textData.getIn([
                            "formats",
                            "others",
                            "isUserFont",
                          ]),
                          isLoaded: true,
                          url: data.textData.getIn([
                            "formats",
                            "others",
                            "cssUrl",
                          ]),
                        })
                      )
                    );
                  state = state.setIn(
                    ["loadedFonts", "fontTracking", data.id],
                    true
                  );
                } else if (loadedFont.get("isLoaded") === false) {
                  const fontIndex = loadedFonts.findIndex(
                    (x) => x.get("load") === value
                  );
                  state = state.setIn(
                    ["loadedFonts", "fontTracking", data.id],
                    true
                  );
                  state = state.setIn(
                    ["loadedFonts", "fonts", fontIndex, "isLoaded"],
                    true
                  );
                }
              }
            }
          } else {
            state = state.setIn(
              ["textOptions", "bullet", "isActive"],
              data.value
            );
          }
          let selectedAll = false;

          if (
            data.selectedAll !== undefined &&
            data.selectedAll &&
            state.getIn(["textStatus", "isFocused"])
          )
            selectedAll = true;

          if (state.getIn(["textStatus", "selectedAll"]) !== selectedAll) {
            state = state.setIn(["textStatus", "selectedAll"], selectedAll);
          }
        }
      });

      break;
    }
    case ActionTypes.UPDATE_TEXT: {
      if (action.data.isGrouped) {
        state = state.set("textUpdated", 0);
      }

      if (action.data.property !== "isBullet") {
        let { value } = action.data;
        const { property } = action.data;
        if (action.data.property === "fontSize")
          value = value !== null ? parseFloat(value, 10) : null;
        if (action.data.property === "letterSpacing")
          value = value !== null ? parseFloat(value, 10) : null;

        let isActive = value !== null;
        if (property === "bullet") {
          isActive = state.getIn(["textOptions", "bullet", "isActive"]);

          if (state.getIn(["textOptions", "textAlign", "value"]) !== "left")
            state = state.setIn(["textOptions", "textAlign", "value"], "left");
        }

        state = state
          .setIn(["textOptions", property, "value"], value)
          .setIn(["textOptions", property, "isActive"], isActive);
        if (property === "fontFamily" && action.data.textData !== undefined) {
          state = state.setIn(["textOptions", "fontFamily", "value"], value);
          if (action.data.fontLoaded === true) {
            const loadedFonts = state.getIn(["loadedFonts", "fonts"]);
            const loadedFont = loadedFonts.find((x) => x.get("load") === value);

            if (loadedFont === undefined) {
              if (action.data.family !== undefined)
                state = state.setIn(
                  ["loadedFonts", "fonts"],
                  loadedFonts.push(
                    fromJS({
                      font: action.data.family,
                      load: value,
                      byUser: action.data.textData.getIn([
                        "formats",
                        "others",
                        "isUserFont",
                      ]),
                      isLoaded: true,
                      url: action.data.textData.getIn([
                        "formats",
                        "others",
                        "cssUrl",
                      ]),
                    })
                  )
                );
              state = state.setIn(
                ["loadedFonts", "fontTracking", action.data.id],
                true
              );
            } else if (loadedFont.get("isLoaded") === false) {
              const fontIndex = loadedFonts.findIndex(
                (x) => x.get("load") === value
              );
              state = state.setIn(
                ["loadedFonts", "fontTracking", action.data.id],
                true
              );
              state = state.setIn(
                ["loadedFonts", "fonts", fontIndex, "isLoaded"],
                true
              );
            }
          }
        }
      } else {
        state = state.setIn(
          ["textOptions", "bullet", "isActive"],
          action.data.value
        );
      }
      let selectedAll = false;

      if (
        action.data.selectedAll !== undefined &&
        action.data.selectedAll &&
        state.getIn(["textStatus", "isFocused"])
      )
        selectedAll = true;

      if (state.getIn(["textStatus", "selectedAll"]) !== selectedAll) {
        state = state.setIn(["textStatus", "selectedAll"], selectedAll);
      }
      break;
    }
    case ActionTypes.UPDATE_GROUP_TEXT: {
      if (payload.propertyUpdate) {
        let { value } = payload;
        const { property } = payload;
        if (payload.property === "fontSize")
          value = value !== null ? parseFloat(value, 10) : null;
        if (payload.property === "letterSpacing")
          value = value !== null ? parseFloat(value, 10) : null;

        let isActive = value !== null;
        if (property === "bullet") {
          isActive = state.getIn(["textOptions", "bullet", "isActive"]);

          if (state.getIn(["textOptions", "textAlign", "value"]) !== "left")
            state = state.setIn(["textOptions", "textAlign", "value"], "left");
        }
        state = state
          .setIn(["textOptions", property, "value"], value)
          .setIn(["textOptions", property, "isActive"], isActive);
        let selectedAll = false;

        if (
          payload.selectedAll !== undefined &&
          payload.selectedAll &&
          state.getIn(["textStatus", "isFocused"])
        )
          selectedAll = true;

        if (state.getIn(["textStatus", "selectedAll"]) !== selectedAll) {
          state = state.setIn(["textStatus", "selectedAll"], selectedAll);
        }
      }
      break;
    }
    case ActionTypes.UPDATE_OBJECT_COMPLETE: {
      const { data } = payload;
      if (
        data.toUpdate.textData !== undefined &&
        data.toUpdate.textData.formats.containerStyle.fontFamily !==
        undefined &&
        data.toUpdate.textData.formats.others.family !== undefined
      ) {
        const loadedFonts = state.getIn(["loadedFonts", "fonts"]);
        if (
          loadedFonts.find(
            (x) =>
              x.get("load") ===
              data.toUpdate.textData.formats.containerStyle.fontFamily
          ) === undefined &&
          data.toUpdate.textData.formats.others.family !== undefined
        ) {
          state = state.setIn(
            ["loadedFonts", "fonts"],
            loadedFonts.push(
              fromJS({
                font: data.toUpdate.textData.formats.others.family,
                load: data.toUpdate.textData.formats.containerStyle.fontFamily,
                byUser: data.toUpdate.textData.formats.others.isUserFont,
                isLoaded: false,
                url: data.toUpdate.textData.formats.others.cssUrl,
                id: data.selectedItem,
              })
            )
          );

          state = state.setIn(
            ["loadedFonts", "fontTracking", data.selectedItem],
            false
          );
        }

        const partialFontArr = preloadTextFonts(
          data.toUpdate.textData.htmlText
        );
        for (const fontItem of partialFontArr) {
          if (
            !loadedFonts.find(
              (loadedFontItem) => loadedFontItem.get("url") === fontItem.url
            )
          ) {
            state = state.setIn(
              ["loadedFonts", "fonts"],
              loadedFonts.push(fromJS(fontItem))
            );
            state = state.setIn(
              ["loadedFonts", "fontTracking", data.selectedItem],
              false
            );
          }
        }
      }
      break;
    }
    case ActionTypes.UPDATE_TRANSFORM_STATUS: {
      state = state.set(
        "transformStatus",
        state.get("transformStatus").merge(fromJS(action.data))
      );
      break;
    }
    case ActionTypes.TOGGLE_CROP: {
      state = state.set("isFit", true);
      state = state.update("isCropping", (value) => !value);
      break;
    }
    case ActionTypes.UPDATE_SWAP_HOVER_DROP: {
      if (action.data.reset === true) {
        state = state.set("swapHoverDrop", DEFAULT_SWAP_HOVER_DROP);
      } else if (action.data.hovering === true) {
        const {
          targetType = ITEM_CONFIG.SWAP_TARGETS.NONE,
          sourceType = ITEM_CONFIG.SWAP_SOURCE.NONE,
          childId = "",
          frameClipId = "",
        } = action.data;

        state = state.setIn(["swapHoverDrop", "id"], action.data.id);
        state = state.setIn(
          ["swapHoverDrop", "hovering"],
          action.data.hovering
        );
        state = state.setIn(["swapHoverDrop", "targetType"], targetType);
        state = state.setIn(["swapHoverDrop", "sourceType"], sourceType);
        state = state.setIn(["swapHoverDrop", "childId"], childId);
        state = state.setIn(["swapHoverDrop", "frameClipId"], frameClipId);
        state = state.setIn(["swapHoverDrop", "transitionLayer"], action.data.transitionLayer);

      } else {
        const { childId = "", frameClipId = "" } = action.data;
        if (
          action.data.id === state.getIn(["swapHoverDrop", "id"]) &&
          childId === state.getIn(["swapHoverDrop", "childId"]) &&
          frameClipId === state.getIn(["swapHoverDrop", "frameClipId"])
        ) {
          state = state.setIn(
            ["swapHoverDrop", "hovering"],
            action.data.hovering
          );
        }
      }
      break;
    }
    case ActionTypes.MOVE_UPDATE:
    case ActionTypes.RESIZE_UPDATE:
    case ActionTypes.MOVE_SUBTITLE:
    case ActionTypes.ROTATION_UPDATE: {
      state = state.set(
        "transformStatus",
        fromJS({
          transforming: false,
          moving: false,
          resizing: false,
          rotating: false,
        })
      );
      // if(state.get("currentPosition") !== null)
      //   state = state.set("currentPosition", null);
      break;
    }
    case ActionTypes.APPLY_FRAME_CLIP_PLOT: {
      state = state.set("isFraming", false);
      state = state.set("frameData", null);
      break;
    }
    case ActionTypes.CANCEL_FRAMING: {
      state = state.set("isFraming", false);
      state = state.set("frameData", null);
      break;
    }
    case ActionTypes.SELECT_FRAME_CLIP: {
      if (action.data.clipId) {
        state = state.setIn(
          ["selectedFrameClip", "clipId"],
          action.data.clipId
        );
        state = state.setIn(["selectedFrameClip", "id"], action.data.id);
        state = state.setIn(
          ["selectedFrameClip", "groupId"],
          action.data.groupId ? action.data.groupId : ""
        );
        state = state.set("isFraming", Boolean(action.data.isFraming));
      } else {
        state = state.set("selectedFrameClip", DEFAULT_SELECTED_FRAME_CLIP);
        state = state.set("isFraming", false);
      }

      if (!state.get("isFraming")) {
        state = state.set("frameData", null);
      }

      break;
    }
    case ActionTypes.CROP_IMAGE: {
      state = state.set(
        "transformStatus",
        fromJS({
          transforming: false,
          moving: false,
          resizing: false,
          rotating: false,
        })
      );
      state = state.set("isCropping", false);
      state = state.set("isFraming", false);
      break;
    }
    case ActionTypes.WRITE_TEXT: {
      if (action.payload.undo) {
        state = state.setIn(["textStatus", "isFocused"], false);
      }
      if (action.payload.isTyping === false) {
        state = state
          .setIn(
            ["textStatus", "currentText"],
            action.payload.toUpdate.textData.htmlText
          )
          .setIn(["textStatus", "currentWidth"], action.payload.toUpdate.width)
          .setIn(
            ["textStatus", "currentHeight"],
            action.payload.toUpdate.height
          )
          .setIn(["textStatus", "currentX"], action.payload.toUpdate.x)
          .setIn(["textStatus", "currentY"], action.payload.toUpdate.y);
      }
      break;
    }
    case ActionTypes.UPDATE_TIMELINE_COMPLETE: {
      const updaterObject = fromJS(action.payload.data);
      let newSelectedItems = state.get("selectedItems");
      let newSelectedAudios = state.get("selectedAudios");
      let newSelectedSubtitles = state.get("selectedSubtitles");
      const emptyList = fromJS([]);
      let addedItems = emptyList;
      let addedAudios = emptyList;
      let addedSubtitles = emptyList;
      let cleanUpItemSelection = false;
      let cleanUpAudioSelection = false;
      let cleanUpSubtitleSelection = false;
      // eslint-disable-next-line
      let minDuration = null;
      let isSplit = false;

      for (const [container, containerData] of updaterObject.entrySeq()) {
        if (container === "project") {
          continue;
        }
        if (container === "subtitle") {
          if (containerData.has("subtitleDropData")) {
            for (const dropId of containerData.get("subtitleDropData").keySeq()) {
              const subtitleSizeBeforeFilter = newSelectedSubtitles.size;
              // selected subtitle (timelineId) will be in pattern itemid-dropid-subtitleitemid
              newSelectedSubtitles = newSelectedSubtitles.filter(
                (id) => id && !id.includes(dropId)
              );
              if (subtitleSizeBeforeFilter !== newSelectedSubtitles.size) {
                cleanUpSubtitleSelection = true;
              }
            }
          }
          if (containerData.has("subtitleData")) {
            for (const subtitleItems of containerData.get("subtitleData").valueSeq()) {
              for (const subtitleItemUpdater of subtitleItems.valueSeq()) {
                const timelineId = subtitleItemUpdater.get("timelineId");
                if (subtitleItemUpdater.get("isDelete")) {
                  const subtitleSizeBeforeFilter = newSelectedSubtitles.size;
                  newSelectedSubtitles = newSelectedSubtitles.filter(
                    (id) => id !== timelineId
                  );
                  if (subtitleSizeBeforeFilter !== newSelectedSubtitles.size) {
                    cleanUpSubtitleSelection = true;
                  }
                } else if (subtitleItemUpdater.get("isNew") && action.isCurrentUser) {
                  if (!isSplit && subtitleItemUpdater.get("isSplit")) {
                    isSplit = true;
                  }
                  addedSubtitles = addedSubtitles.push(timelineId);
                }
              }
            }
          }
          continue;
        }

        for (const [itemId, itemUpdater] of containerData.entrySeq()) {
          if (container === "workspaceItems") {
            if (itemUpdater.get("isDelete")) {
              const sizeBeforeFilter = newSelectedItems.size;
              newSelectedItems = newSelectedItems.filter((id) => id !== itemId);
              if (sizeBeforeFilter !== newSelectedItems.size) {
                cleanUpItemSelection = true;
              }
            } else if (
              itemUpdater.get("isNew") &&
              action.isCurrentUser &&
              !itemUpdater.get("isChangeText")
            ) {
              if (minDuration === null) {
                minDuration = itemUpdater.getIn(["data", "enterStart"]);
              }
              if (
                minDuration !== null &&
                minDuration > itemUpdater.getIn(["data", "enterStart"])
              ) {
                minDuration = itemUpdater.getIn(["data", "enterStart"]);
              }
              if (!isSplit && itemUpdater.get("isSplit")) {
                isSplit = true;
              }
              addedItems = addedItems.push(itemId);
            } else if (itemUpdater.getIn(["data", "frameClipImage"])) {
              const clipId = itemUpdater.getIn([
                "data",
                "frameClipImage",
                "clipId",
              ]);
              const imgDetails = itemUpdater.getIn([
                "data",
                "frameClipImage",
                "imgDetails",
              ]);
              const isFrameClipSelected =
                itemId === state.getIn(["selectedFrameClip", "id"]) &&
                clipId === state.getIn(["selectedFrameClip", "clipId"]);

              if (isFrameClipSelected) {
                if (state.get("isFraming")) {
                  state = state.set("isFraming", false);
                }

                const isClipDelete =
                  imgDetails &&
                  imgDetails.get("id") === BASE_IMAGE_DETAILS.get("id");
                if (isClipDelete) {
                  state = state.set("propertyWindow", PropertyWindowRecord());
                }
              }
            }
            if (
              itemUpdater.get("data")
              && (!itemUpdater.get("isNew") || itemUpdater.get("isReplace"))
            ) {
              const updaterData = itemUpdater.get("data");
              if (updaterData.has("enterStart") || updaterData.has("exitEnd")) {
                const subtitleSizeBeforeFilter = newSelectedSubtitles.size;
                // selected subtitle (timelineId) will be in pattern itemid-dropid-subtitleitemid
                newSelectedSubtitles = newSelectedSubtitles.filter(
                  (id) => id && !id.includes(itemId)
                );
                if (subtitleSizeBeforeFilter !== newSelectedSubtitles.size) {
                  cleanUpSubtitleSelection = true;
                }
              }
            }
          } else if (container === "audios") {
            if (itemUpdater.get("isDelete")) {
              const audioSizeBeforeFilter = newSelectedAudios.size;
              newSelectedAudios = newSelectedAudios.filter(
                (id) => id !== itemId
              );
              if (audioSizeBeforeFilter !== newSelectedItems.size) {
                cleanUpAudioSelection = true;
              }
            } else if (itemUpdater.get("isNew") && action.isCurrentUser) {
              if (minDuration === null) {
                minDuration = itemUpdater.getIn(["data", "playStart"]);
              }
              if (
                minDuration !== null &&
                minDuration > itemUpdater.getIn(["data", "playStart"])
              ) {
                minDuration = itemUpdater.getIn(["data", "playStart"]);
              }
              if (!isSplit && itemUpdater.get("isSplit")) {
                isSplit = true;
              }
              addedAudios = addedAudios.push(itemId);
            }
            if (!itemUpdater.get("isNew") && itemUpdater.get("data")) {
              const updaterData = itemUpdater.get("data");
              if (updaterData.has("playStart") || updaterData.has("playEnd")) {
                const subtitleSizeBeforeFilter = newSelectedSubtitles.size;
                // selected subtitle (timelineId) will be in pattern itemid-dropid-subtitleitemid
                newSelectedSubtitles = newSelectedSubtitles.filter(
                  (id) => id && !id.includes(itemId)
                );
                if (subtitleSizeBeforeFilter !== newSelectedSubtitles.size) {
                  cleanUpSubtitleSelection = true;
                }
              }
            }
          }
        }
      }

      if (minDuration !== null) {
        state = state.set("playhead", minDuration);
      }
      if (!isSplit && addedItems.size) {
        state = state.set("selectedItems", addedItems);
        cleanUpItemSelection = true;
        if (addedAudios.size === 0 && state.get("selectedAudios").size > 0) {
          newSelectedAudios = emptyList;
          cleanUpAudioSelection = true;
        }
        if (
          addedSubtitles.size === 0 &&
          state.get("selectedSubtitles").size > 0
        ) {
          newSelectedSubtitles = emptyList;
          cleanUpSubtitleSelection = true;
        }
      } else if (newSelectedItems !== state.get("selectedItems")) {
        state = state.set("selectedItems", newSelectedItems);
      }
      if (!isSplit && addedAudios.size) {
        state = state.set("selectedAudios", addedAudios);
        cleanUpAudioSelection = true;
        if (addedItems.size === 0 && state.get("selectedItems").size > 0) {
          state = state.set("selectedItems", emptyList);
          cleanUpItemSelection = true;
        }
        if (
          addedSubtitles.size === 0 &&
          state.get("selectedSubtitles").size > 0
        ) {
          newSelectedSubtitles = emptyList;
          cleanUpSubtitleSelection = true;
        }
      } else if (newSelectedAudios !== state.get("selectedAudios")) {
        state = state.set("selectedAudios", newSelectedAudios);
      }
      if (!isSplit && addedSubtitles.size) {
        state = state.set("selectedSubtitles", addedSubtitles);
        cleanUpSubtitleSelection = true;
        if (addedItems.size === 0 && state.get("selectedItems").size > 0) {
          state = state.set("selectedItems", emptyList);
          cleanUpItemSelection = true;
        }
        if (addedAudios.size === 0 && state.get("selectedAudios").size > 0) {
          state = state.set("selectedAudios", emptyList);
          cleanUpAudioSelection = true;
        }
      } else if (newSelectedSubtitles !== state.get("selectedSubtitles")) {
        state = state.set("selectedSubtitles", newSelectedSubtitles);
      }

      if (
        !isSplit &&
        (addedAudios.size || addedItems.size || addedSubtitles.size)
      ) {
        state = state.set("timelineSelectionAutoScrollToken", uuid());
      }

      // close the setting panel on audio delete
      if (cleanUpAudioSelection) {
        state = state.set("propertyWindow", PropertyWindowRecord());
      }

      // close the setting panel on item delete
      if (cleanUpItemSelection) {
        state = state.set("propertyWindow", PropertyWindowRecord());

        if (state.getIn(["selectedFrameClip", "clipId"])) {
          // reset on item drop
          state = state.set("selectedFrameClip", DEFAULT_SELECTED_FRAME_CLIP);
        }
        state = state.set("isCropping", false);
        state = state.set("isFraming", false);
        if (
          state.getIn(["textStatus", "id"]) !== undefined &&
          state.getIn(["textStatus", "id"]) !== null
        ) {
          state = state.set("textStatus", TextStatusRecord());
        }
      }

      if (cleanUpSubtitleSelection) {
        state = state.set("propertyWindow", PropertyWindowRecord());
      }

      if (action.isCurrentUser) {
        state = state.set("timelineResetToken", uuid());
      }

      break;
    }
    case ActionTypes.UPDATE_IMPERATIVE_ROTATE: {
      if (payload.reset) {
        state = state.set("imperativeRotate", ImperativeRotateRecord());
      } else {
        state = state.setIn(["imperativeRotate", "rotateTo"], payload.rotateTo);
        state = state.setIn(["imperativeRotate", "token"], uuid());
      }
      break;
    }
    case ActionTypes.UPDATE_RECENT_COLORS: {
      state = state.set("recentColors", payload.colors);
      break;
    }
    case ActionTypes.SET_COLOR_THEME_DATA: {
      if (payload.themes) {
        state = state.set("themes", fromJS(payload.themes));
      }
      if (payload.colorPalettes) {
        state = state.set("colorPalettes", fromJS(payload.colorPalettes));
      }
      if (payload.brand) {
        state = state.set("brandColorPalettes", fromJS(payload.brand));
      }
      if (!state.getIn(["initStages", "colorPaletteLoaded"])) {
        state = state.setIn(["initStages", "colorPaletteLoaded"], true);
      }
      break;
    }
    case ActionTypes.CHOOSE_FONT: {
      state = state.set("selectedFontItem", action.data);
      state = state.set("isFontLoading", true);
      break;
    }
    case ActionTypes.UPDATE_FONT_LOAD_STATUS: {
      state = state.set("isFontLoading", action.data);
      break;
    }
    case ActionTypes.UPDATE_ACTIVE_FONT_NAME: {
      state = state.set("activeFontName", action.data);
      break;
    }
    case ActionTypes.UPDATE_ACTIVE_FONT_FAMILY: {
      state = state.set("activeFontFamily", action.data);
      break;
    }
    case ActionTypes.UPDATE_TEXT_APPLY_ALL_DATA: {
      state = state.set("textApplyAllData", action.data);
      break;
    }
    case ActionTypes.UPDATE_TEXT_APPLY_ALL_OPTIONS: {
      if (action.data.reset) {
        state = state.set("txtApplyAllOptions", TextApplyAllOptionsRecord());
      } else {
        const txtApplyAllOptions = state.get("txtApplyAllOptions").merge(fromJS(action.data.options));
        state = state.set("txtApplyAllOptions", txtApplyAllOptions);
      }
      break;
    }
    case ActionTypes.SET_CLIPBOARD_DATA: {
      state = state.set("clipboardData", payload);
      break;
    }
    case ActionTypes.SET_SWAP_ITEM:
      state = state.set("swapDetails", payload);
      break;
    case ActionTypes.SET_SWAP_INFO:
      if (!state.get("swapInfo")) {
        state = state.set("swapInfo", fromJS({}));
      }
      state = state.set("swapInfo", state.get("swapInfo").merge(fromJS(payload)));
      break;
    case ActionTypes.RESET_SWAP:
      state = state.update(state => state.set("swapDetails", null).set("swapInfo", null));
      break;
    case ActionTypes.SET_ANIMO_PLAYER:
      state = state.set("isAnimoPlayer", payload.isAnimoPlayer);
      break;
    case ActionTypes.SET_SHORTS_PLAYER:
      state = state.set("isShortsPlayer", payload.isShortsPlayer);
      break;
    case ActionTypes.SET_ANIMO_ID:
      state = state.set("animoPlayerId", payload);
      break;
    case ActionTypes.SET_SHORT_CONTAINER_ID:
      state = state.set("shortContainerId", payload);
      break;
    case ActionTypes.SET_GLOBAL_VOLUME:
      state = state.set("globalVolume", payload.globalVolume);
      break;
    case ActionTypes.SET_PAUSE:
      state = state.set("isPausePlayer", payload.isPause);
      state = state.setIn(["runningState", "seekPlayhead"], payload.playhead);
      state = state.setIn(["runningState", "seekToken"], uuid());
      break;
    case ActionTypes.SET_ANIMO_FULL_SCREEN:
      state = state.set("isFullScreen", payload.isFullScreen);
      break;
    case ActionTypes.SET_SUBTITLE_STATUS: {
      const { type, toMerge } = payload;
      state = state.updateIn(["subtitle", type], (data) => data.merge(toMerge));
      break;
    }
    case ActionTypes.INSERT_SUBTITLE_DATA_COMPLETE: {
      const { fontToLoad } = action.payload;
      let loadedFonts = state.getIn(["loadedFonts", "fonts"]);
      for (let index = 0; index < fontToLoad.length; index += 1) {
        loadedFonts = loadedFonts.push(fromJS(fontToLoad[index]));
      }
      state = state.setIn(["loadedFonts", "fonts"], loadedFonts);
      break;
    }
    case ActionTypes.SET_DETACH_DATA:
      if (!state.get("detachAudioData")) {
        state = state.set("detachAudioData", fromJS({}));
      }
      state = state.set("detachAudioData", fromJS(payload));
      break;
    case ActionTypes.RESET_DETACH_DATA:
      state = state.set("detachAudioData", null);
      break;
    case ActionTypes.SET_BACKDROP_LOADER: {
      state = state.setIn(["backdropLoader", "isLoading"], payload.isLoading);
      state = state.setIn(["backdropLoader", "loaderMessage"], payload.loaderMessage);
      break;
    }
    case ActionTypes.ADD_UPLOAD_HISTORY: {
      state = state.updateIn(
        ["uploadHistory"],
        uploadHistory => uploadHistory.merge(fromJS(action.payload))
      );
      break;
    }
    case SET_UPGRADE_MODAL: {
      state = state.set("upgradeModal", payload.upgradeModal);
      break;
    }
    case ActionTypes.SET_SUBTITLE_CALLBACK: {
      state = state.setIn(["subtitleCallback"], payload.subtitleCb);
      break;
    }
    case ActionTypes.SET_DRAGGED_TRANSITION: {
      if (payload.dragging) {
        state = state.setIn(["draggedTransitionData", "prevItemId"], payload.prevId);
        state = state.setIn(["draggedTransitionData", "nextItemId"], payload.nextId);
        state = state.setIn(["draggedTransitionData", "effect"], payload.effect);
      } else if (!payload.dragging) {
        state = state.deleteIn(["draggedTransitionData"]);
      }
      break;
    }
    case ActionTypes.SET_LANGUAGES:
      state = state.set("languages", payload);
      break;
    case ActionTypes.TRIGGER_SUBTITLE_RECONCILE_COMPLETE: {
      if (payload.data.subtitleId) {
        state = state.setIn(["subtitle", "reconcileStatus", "token"], uuid());
        state = state.setIn(
          ["subtitle", "reconcileStatus", "status"],
          "pending"
        );
        state = state.setIn(
          ["subtitle", "reconcileStatus", "subtitleId"],
          payload.data.subtitleId
        );
        state = state.setIn(["subtitle", "reconcileStatus", "fromApi"], true);
      }
      break;
    }
    case ActionTypes.SET_PRESET_EFFECT: {
      state = state.set("preset", payload);
      break;
    }
    default:
      break;
  }

  if (type === ActionTypes.UPDATE_SUBTITLE_LIST_COMPLETE || type === ActionTypes.UPDATE_TIMELINE_COMPLETE) {
    if (action.shouldReconcile && action.subtitleId) {
      state = state.setIn(["subtitle", "reconcileStatus", "token"], uuid());
      state = state.setIn(
        ["subtitle", "reconcileStatus", "status"],
        "pending"
      );
      state = state.setIn(
        ["subtitle", "reconcileStatus", "subtitleId"],
        action.subtitleId
      );
      state = state.setIn(["subtitle", "reconcileStatus", "fromApi"], false);
    }
  }

  if (type === ActionTypes.UPDATE_TIMELINE_COMPLETE && action.payload.actionRecovery) {
    state = state.set("actionRecovery", fromJS(action.payload.actionRecovery));
  } else if (type.endsWith("Complete") && state.get("actionRecovery")) {
    state = state.set("actionRecovery", undefined);
  }

  if (
    type === ActionTypes.TOGGLE_CROP ||
    type === ActionTypes.SELECT_FRAME_CLIP
  ) {
    const propertyWindow = state.get("propertyWindow");
    const isCropping = state.get("isCropping");
    const isFraming = state.get("isFraming");
    if (propertyWindow.get("isOpened") && (isCropping || isFraming)) {
      state = state.set("propertyWindow", PropertyWindowRecord());
    }
  }

  if (
    prevPropertyWindow !== state.get("propertyWindow")
    && state.getIn(["propertyWindow", "isOpened"])
    && state.getIn(["propertyWindow", "component"]) !== "subtitle_settings"
    && state.getIn(["propertyPanel", "selectedPanel"]) === PANEL.SUBTITLE
  ) {
    state = state.setIn(["propertyPanel", "selectedPanel"], PANEL.PROPERTIES);
  }

  let workspaceStage = state.get("workspaceStage");
  let propertyWindow = state.get("propertyWindow");
  let propertyPanel = state.get("propertyPanel");

  const updateStage =
    propertyWindow !== prevPropertyWindow ||
    propertyPanel !== prevPropertyPanel;

  if (updateStage) {
    if (propertyPanel.get("isExpand") || propertyWindow.get("isOpened")) {
      let workspaceStage = state.get("workspaceStage");
      if (
        (propertyPanel.get("isExpand") && propertyPanel.get("selectedPanel") === PANEL.SUBTITLE)
        || (propertyWindow.get("isOpened") && propertyWindow.get("component") === "subtitle_settings")
      ) {
        workspaceStage = workspaceStage.setIn(["left", "library"], 600);
      } else {
        workspaceStage = workspaceStage.deleteIn(["left", "library"]);
      }
      state = state.set("workspaceStage", workspaceStage);
    } else {
      state = state.setIn(["workspaceStage", "left", "library"], 0);
    }
  }

  workspaceStage = state.get("workspaceStage");
  propertyWindow = state.get("propertyWindow");
  propertyPanel = state.get("propertyPanel");

  const updateZoom =
    workspaceStage !== prevWorkspaceStage ||
    propertyWindow !== prevPropertyWindow ||
    propertyPanel !== prevPropertyPanel;

  if (
    (!state.get("isMobileView") &&
      (updateZoom || type === SET_PROPERTY_PANEL || type === SET_EXPAND)) ||
    type === ActionTypes.SET_TIMELINE_HEIGHT ||
    type === ActionTypes.SET_PROJECT ||
    type === ActionTypes.CHANGE_ZOOM ||
    type === ActionTypes.TOGGLE_CROP ||
    type === ActionTypes.SET_PLAY_ALL ||
    type === ActionTypes.SET_RESIZE
  ) {
    state = state.mergeDeep(
      getZoom({
        workspaceStage: state.get("workspaceStage"),
        isFit: state.get("isFit"),
        projectWidth: state.get("projectWidth"),
        projectHeight: state.get("projectHeight"),
        zoomFactorToUse: state.get("zoomFactor"),
        isAnimoPlayer: state.get("isAnimoPlayer"),
        isShortsPlayer: state.get("isShortsPlayer"),
        animoPlayerId: state.get("animoPlayerId"),
        isFullScreen: state.get("isFullScreen"),
      })
    );
  }

  if (prevPropertyWindow.get("isOpened") !== propertyWindow.get("isOpened")) {
    state = state.set("animatePropertyWindow", !prevPropertyPanel.get("isExpand") && !propertyPanel.get("isExpand"));
  }

  if (propertyPanel.get("isExpand") !== prevPropertyPanel.get("isExpand")) {
    state = state.set(
      "animateLibrary",
      !prevPropertyWindow.get("isOpened") && !propertyWindow.get("isOpened")
    );
  }

  const prevLeftIsOpen =
    prevPropertyWindow.get("isOpened") || prevPropertyPanel.get("isExpand");
  const leftIsOpen =
    propertyWindow.get("isOpened") || propertyPanel.get("isExpand");
  if (leftIsOpen !== prevLeftIsOpen) {
    const token = randomString().substring(0, 5);
    state = state.set("itemtoolAnimationToken", token);
  }

  return state;
}
