import styled, { css } from "styled-components";
import Tile from "../../common-components/Tile";
import MusicTile from "../../common-components/MusicTile";
import Action from "../../common-components/Action";
import { AudioFile } from "./library-panels/upload-panel/folder-audiofile";
import { APP_Z_INDICES } from "../../constants";
import { font } from "../../constants/font";
import { NumberInputStyled } from "../../common-components/NumberInputComponent";
import { STATIC_PATH } from "../../constants/config";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 19px;
  margin: ${(props) => props.margin || "auto"};
  @media (max-width: 768px) {
    margin: 10px 0px 20px;
  }
`;

export const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 36px;
  padding: 0px 8px;
  margin: ${(props) => props.margin || "auto"};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.primaryBorderColor};
  background-color: ${(props) => props.theme.polarColor};
  box-shadow: 0 0 6px ${(props) => props.theme.rgbaBlack26};
  @media (max-width: 768px) {
    svg {
      width: 20px;
      height: 20px;
      margin-top: 5px;
    }
  }
`;

export const VideoSection = styled.div`
  width: ${(props) => props.width || "316px"};
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent || "start"};
  column-gap: ${(props) => props.columnGap || "8px"};
  row-gap: ${(props) => props.row || "0px"};
  padding-left: ${(props) => props.padding || "4px"};
  &.PropertyPanel .tile-container .smart-img {
    padding: 8px;
  }
  .tile-container {
    &:hover {
      outline: unset;
      border: 2px solid ${(props) => props.theme.secondaryBorderColor};
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    &.PropertyPanel .tile-container {
      width: 57px;
      height: 57px;
      margin: 7px 9px 8px;
    }
  }
`;

export const ImageSection = styled.div`
  width: ${(props) => props.width || "316px"};
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  column-gap: ${(props) => props.columnGap || "8px"};
  padding-left: 4px;
  /* height: 100%; */
  .tile-container {
    position: relative;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Column = styled.div`
  column-count: ${(props) => props.columnCount || 2};
  column-gap: 4px;
  flex: 1;
  &.verticalColumn {
    column-count: 3;
  }
  @media screen and (max-width: 767px) {
    column-count: ${(props) => props.mobileColumnCount || 2};
  }
`;

export const ScrollWrapper = styled.div`
  width: ${(props) => props.width || "316px"};
  min-height: ${(props) => props.minHeight || "auto"};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SettingsWrapper = styled.div`
  width: 316px;
  margin: ${(props) => props.margin};
  ${(props) =>
    props.isShowBorder &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.primaryBorderColor};
    `}
  ${(props) =>
    props.isCustomButton &&
    css`
      .settings-action-style {
        width: 316px;
        height: ${(props) => props.buttonHeight || "40px"};
        flex-direction: row;
        background: ${(props) => props.theme.polarColor} 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 0.5px 1px ${(props) => props.theme.rgbaBlack};
        border: 1px solid ${(props) => props.theme.primaryBorderColor};
        label {
          padding: 8px;
        }
        &:hover {
          border-color: ${(props) => props.theme.secondaryBorderColor};
        }
      }
    `}
`;

export const Button = styled.div`
  cursor: pointer;
  margin: ${(props) => props.margin};
  &:hover {
    img {
      transform: scale(1.02);
      transition: transform 0.2s ease-in-out;
    }
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 316px;
  img {
    height: 100px;
    width: 100px;
  }
`;

export const Container = styled.div`
  display: ${(props) => props.display || "block"};
  margin: ${(props) => props.margin || "auto"};
`;

export const Scroll = styled.div`
  height: ${(props) => props.height || "680px"};
  width: ${(props) => props.width || "104%"};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    transform: matrix(0, 1, -1, 0, 0, 0);
    background: ${(props) => props.theme.primaryBorderColor} 0% 0% no-repeat
      padding-box;
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    height: calc(500px - 50px);
    padding: 0px 20px 0px 10px;
    touch-action: auto;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  margin: 0px 0px 20px 0px;
`;

export const CommonLibraryDragStyles = css`
  &.is-dragging {
    opacity: 0;
  }
  &.is-drag-started {
    pointer-events: none;
  }
`;

export const LibraryDraggableTile = styled(Tile)`
  ${CommonLibraryDragStyles}
`;
LibraryDraggableTile.displayName = "LibraryDraggableTile";

export const LibraryDraggableMusicTile = styled(MusicTile)`
  ${CommonLibraryDragStyles}
`;
LibraryDraggableMusicTile.displayName = "LibraryDraggableMusicTile";

export const LibraryDraggableFolderMusicTile = styled(AudioFile)`
  ${CommonLibraryDragStyles}
`;
LibraryDraggableFolderMusicTile.displayName = "LibraryDraggableFolderMusicTile";

export const LibraryDraggableTextTile = styled(Action)`
  ${CommonLibraryDragStyles}
`;
LibraryDraggableTextTile.displayName = "LibraryDraggableTextTile";

export const LibraryDragItem = styled.div`
  position: absolute;
  z-index: ${APP_Z_INDICES.libraryDragItem.default};
  pointer-events: none;

  * {
    pointer-events: none;
  }

  video {
    display: none;
  }

  > div {
    width: 100% !important;
    height: 100% !important;
  }
`;
LibraryDragItem.displayName = "LibraryDragItem";

export const DefaultTextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
`;
DefaultTextSection.displayName = "DefaultTextSection";

export const VersionHeader = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.btnBgLightColor} 0% 0% no-repeat
    padding-box;
  margin: 15px 0;
`;
export const VersionUl = styled.ul`
  padding: 0px 0px 0px;
  line-height: normal;
  list-style: none;
  margin: 0;
  padding: 0;
`;
export const VersionLi = styled.li`
  height: 60px;
  margin: 0 0 10px;
  padding: 0 20px;
  position: relative;
  .restore-btn {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    color: ${(props) => props.theme.polarColor};
    background: transparent ${(props) => props.theme.headerColor} 0% 0%
      no-repeat padding-box;
    border-radius: 4px;
    padding: 8px 14px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin: 15px 7px 0 0;
    cursor: pointer;
    transition: all 0.4s linear;
    &:hover {
      transform: scale(1.05);
      background: transparent ${(props) => props.theme.headerHoveColor} 0% 0%
        no-repeat padding-box;
    }
    label {
      color: ${(props) => props.theme.polarColor} !important;
    }
  }
  &:hover {
    background: ${(props) => props.theme.btnBgLightColor} 0% 0% no-repeat
      padding-box;
    label {
      color: ${(props) => props.theme.secondaryLabelTextColor};
    }
    .restore-btn {
      display: flex;
    }
  }

  ${(props) =>
    props.isSelected &&
    css`
      background: ${(props) => props.theme.btnBgLightColor} 0% 0% no-repeat
        padding-box;
      label {
        color: ${(props) => props.theme.secondaryLabelTextColor};
      }
      .restore-btn {
        display: flex;
      }
    `}
`;
export const RestoreTime = styled.span`
  position: relative;
  top: 8px;
  height: 25px;
  text-align: left;
  display: block;
  font: normal normal normal 15px/19px PT Sans;
  letter-spacing: 0;
  color: ${(props) => props.theme.panelTextColor};
`;

export const UserDetail = styled.div`
  position: relative;
  top: 5px;
  color: ${(props) => props.theme.panelTextColor};
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const ProfileIcon = styled.img`
  border-radius: 50px;
  width: 24px;
  height: 24px;
  object-fit: cover;
  border: 1px solid ${(props) => props.theme.boxShadowColor};
`;
export const MessageWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  background-color: ${(props) => props.theme.rgbaBlack56};
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: self-start;
`;
export const InnerWrap = styled.div`
  background-color: ${(props) => props.theme.blueViolet};
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 2px 18px;
  border-radius: 0px 0px 8px 8px;
  z-index: 99999;
  label {
    color: ${(props) => props.theme.polarColor};
    padding: 5px 0;
  }
  .refresh-btn {
    text-decoration: underline;
  }
`;

export const TextAnimOption = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  column-gap: ${(props) => props.gap};
  text-align: center;
  row-gap: 30px;
`;

export const TextWrapper = styled.div`
  width: 67px;
  transition: all 0.3s;
  cursor: pointer;
  img {
    width: ${(props) => props.iconSize};
    height: ${(props) => props.iconSize};
    border: ${(props) =>
    props.selectedIcon
      ? `2px solid ${props.theme.secondaryBorderColor}`
      : "2px solid transparent"};
    border-radius: 16px;
    transform: ${(props) =>
    props.selectedIcon ? "translateY(-1px)" : "translateY(0)"};

    transition: all 0.3s;
    cursor: pointer;
  }
  &:hover {
    img {
      border: 2px solid ${(props) => props.theme.secondaryBorderColor};
    }
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px 2px !important;
  .effectTitle {
    padding: 16px 0px 2px !important;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 16px;
  border-top: 1px solid #e3e4e8;
  border-bottom: 1px solid #e3e4e8;
`;

export const Sensitivity = styled.div`
  padding: 16px;
  .heading {
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.propertyColor};
    font: ${font.mediumMini};
  }

  .slider {
    grid-column: auto / span 2;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    > div,
    > div input {
      width: 100%;
    }
  }
  .sensitivity-sec {
    display: flex;
    margin-bottom: 10px;
  }
  ${NumberInputStyled} {
    width: 52px;
    margin-left: auto;
    height: 100%;
    background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
    border: 1px solid ${(props) => props.theme.primaryBorderColor};
    border-radius: 4px;
    cursor: text;

    form {
      border-radius: 4px;
      overflow: hidden;
    }

    input {
      font: ${font.normalBase};
      color: ${(props) => props.theme.panelPrimaryColor};
      text-align: center;
    }
  }
`;

export const AppWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0px;
`;

export const AppLists = styled.li.attrs((props) => {
  return {
    style: {
      "--ic-borderColor": props.theme.primaryBorderColor,
      "--ic-secondaryBorderColor": props.theme.secondaryBorderColor,
    },
  };
})`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: 1px solid var(--ic-borderColor);
  border-radius: 8px;
  margin: 10px 0px;
  cursor: pointer;
  width: 98%;
  transition: all 0.3s;
  &:hover {
    transform: scale(0.99);
    border: 1px solid var(--ic-secondaryBorderColor);
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  gap: 3px;
  cursor: pointer;
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 8px;
  padding: 20px 0px 0px;
  justify-content: center;
`;

export const Avatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  cursor: pointer;
`;

export const AvatarImageWrapper = styled.div.attrs((props) => {
  return {
    style: {
      "--avatar-active": props.theme.secondaryBorderColor,
      "--avatar-borderColor": props.theme.avatarBorderColor,
    },
  };
})`
  width: 100%;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.01);
    .avatar-image {
      border: 1px solid var(--avatar-active);
    }
  }
  .avatar-image {
    width: 100%;
    border-radius: 8px;
    min-height: 80px;
    border: 1px solid var(--avatar-borderColor);
  }
`;
AvatarImageWrapper.displayName = "AvatarImageWrapper";

export const SelectedAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 5px 0px;
  height: ${props => props.height};
  overflow-y: auto;
  @media screen and (max-width: 767px) {
    height: 55vh;
    .generate-video,
    .upgrade-btn {
      width: 100%;
    }
  }
`;
SelectedAvatarContainer.displayName = "SelectedAvatarContainer";

export const BackButton = styled.div`
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font: ${font.boldBase};
  &::before {
    content: url(${STATIC_PATH}st-left-arrow.svg);
    position: absolute;
    left: -5px;
    top: -3px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;
